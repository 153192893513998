import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "addForm"];

  initialize() {
    console.log('new material equiv initialized')
  }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  open() {
    this.triggerTarget.classList.add("hidden")
    this.addFormTarget.classList.remove("hidden");
  }

  close() {
    this.triggerTarget.classList.remove("hidden")
    this.addFormTarget.classList.add("hidden");
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.close()
    }
  }

}