// Import and register all your controllers from the importmap under controllers/*

// import { application } from "controllers/application"

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import Notification from '@stimulus-components/notification'
import Carousel from '@stimulus-components/carousel'
import HwComboboxController from "@josefarias/hotwire_combobox"

const application = Application.start()
application.register('notification', Notification)
application.register('carousel', Carousel)
application.register("hw-combobox", HwComboboxController)
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

