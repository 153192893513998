import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "parentCategory", "inputSearch", "equivalence", "subCategory", "emptyMessage"];
  static values = { activeCategory: Number, searchMode: Boolean, densityMax: Number, densityMin: Number, densitySelected: Boolean }

  initialize() {
    console.log("Material_table controller initialized")
    // S'il y a un filtre de recherche, on met la tabs de tous les matériaux active
    this.emptyMessageTarget.classList.add("hidden")
    this.densitySelected = false
    this.checkSearchMode()
    if (this.inputSearchTarget.value) {
      this.filter()
    }
    this.debouncedFilter = debounce(this.filter.bind(this), 200);
  }

  active(event) {
    this.activeTabId = this.tabTargets.indexOf(event.target)
    this.showCurrentActiveTab()
    this.displayActiveTable()
  }

  selectDensity(event) {
    if (event.target.value === "all") {
      this.densitySelected = false
    } else {
      this.densitySelected = true
      const selectedDensityScale = event.target.value.split('-')
      if (selectedDensityScale.length === 2) {
        [this.densityMin, this.densityMax] = selectedDensityScale
      }
    }
    this.filter()
  }

  checkSearchMode() {
    if (this.tabTargets.length > 0 && this.inputSearchTarget.value) {
      this.searchMode = true
      this.activeTabId = 0
    } else {
      this.searchMode = false
      this.activeTabId = 1
    }
    this.showCurrentActiveTab()
    this.displayActiveTable()
  }

  showCurrentActiveTab() {
    this.tabTargets.forEach((element, index) => {
      if (index === this.activeTabId) {
        element.classList.add("active")
        element.classList.remove("inactive")
      } else {
        element.classList.add("inactive")
        element.classList.remove("active")
      }
    })
  }

  displayActiveTable() {
    if (this.activeTabId === 0) {
      this.parentCategoryTargets.forEach(element => element.classList.remove("hidden"))
    } else {
      this.parentCategoryTargets.forEach((element, index) => {
        if (index + 1 !== this.activeTabId) {
          element.classList.add("hidden")
        } else {
          element.classList.remove("hidden")
        }
      })
    }
    this.showEmptyState()
  }

  showEmptyState() {
    const displayedParentCategories = this.parentCategoryTargets.filter(parentCategory => !parentCategory.classList.value.includes('hidden'))
    const displayedSubCategory = displayedParentCategories.some(elem => {
      return Array.from(elem.children).some(childElem => {
        return !childElem.classList.contains('hidden')
      })
    })

    if (displayedSubCategory) {
      this.emptyMessageTarget.classList.add("hidden")
    } else {
      this.emptyMessageTarget.classList.remove("hidden")
    }
  }

  filter() {
    requestAnimationFrame(() => {
      // Si valeur dans la searchbar
      if (this.inputSearchTarget.value) {
        this.subCategoryTargets.forEach((subCategory) => {
          let hasEquivToShow = false
          const equivalenceRows = subCategory.querySelectorAll('tr.equivalence')
          equivalenceRows.forEach((equiv) => {
            const materialNodes = equiv.querySelectorAll("span.material-name")
            const materialNames = Array.from(materialNodes).map(el => el.innerText)
            const materialMatches = this.isMatchingSearchBar(materialNames)
            if (materialMatches) {
              hasEquivToShow = true
              equiv.classList.remove("hidden")
            } else {
              equiv.classList.add("hidden")
            }
          })
          let densityChecked = true
          if (this.densitySelected) {
            densityChecked = this.isMatchingDensity(subCategory.dataset.density)
          }
          const displaySubCategory = hasEquivToShow && densityChecked
          displaySubCategory ? subCategory.classList.remove("hidden") : subCategory.classList.add("hidden")
        })
      // Si pas de valeur dans la searchbar
      } else {
        this.subCategoryTargets.forEach((subCategory) => {
          if (this.densitySelected) {
            if (this.isMatchingDensity(subCategory.dataset.density)) {
              subCategory.classList.remove("hidden")
              const equivalenceRows = subCategory.querySelectorAll('tr.equivalence')
              equivalenceRows.forEach((equiv) => {
                equiv.classList.remove("hidden")
              })
            } else {
              subCategory.classList.add("hidden")
            }
          } else {
            subCategory.classList.remove("hidden")
            const equivalenceRows = subCategory.querySelectorAll('tr.equivalence')
            equivalenceRows.forEach((equiv) => {
              equiv.classList.remove("hidden")
            })
          }
        })
      }
      this.showEmptyState()
    })
  }

  isMatchingSearchBar(materialNames) {
    return materialNames.some(el => el.toLowerCase().includes(this.inputSearchTarget.value.toLowerCase()))
  }

  isMatchingDensity(density) {
    if (this.densityMin === null && this.densityMax === null) return true
    return density >= this.densityMin && density < this.densityMax
  }
}

// Fonction de debounce
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
