import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['inputSearch', 'item']
  initialize() {
    console.log('Generic search controller initialized !')
    this.debouncedFilter = debounce(this.filter.bind(this), 200);
  }

  filter() {
    requestAnimationFrame(() => {
      console.log('this.inputSearchTarget.value :', this.inputSearchTarget.value)
      this.itemTargets.forEach((item) => {
        const material_name = item.querySelector('#material_name').innerText
        console.log('material_name :', material_name)
        if (!material_name.includes(this.inputSearchTarget.value)) {
          item.classList.add('hidden')
        } else {
          item.classList.remove('hidden')
        }
      })
    })
  }
}

// Fonction de debounce
function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}