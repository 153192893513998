import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["popUp"];

  initialize() {
    console.log('modal equiv initialized')
  }

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  open() {
    this.popUpTarget.classList.remove("hidden");
  }

  close() {
    this.popUpTarget.classList.add("hidden");
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.close()
    }
  }

}