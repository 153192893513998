import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "processItem", "emptyMessage"];
  static values = { index: Number, activeProcesses: Array }

  initialize() {
    this.activeTabs = ["small", "medium", "large"]
    this.displayActiveTabs()
    this.displayProcessItems()
  }

  displayActiveTabs() {
    this.tabTargets.forEach(element => {
      const tabProcess = element.dataset.process
      const checkIcon = element.querySelector('svg.check-icon')
      if (this.activeTabs.includes(tabProcess)) {
        element.classList.add("border-cyan-800")
        element.classList.remove("border-gray-300")
        checkIcon.classList.add("text-cyan-800")
        checkIcon.classList.remove("text-gray-400")
      } else {
        element.classList.add("border-gray-300")
        element.classList.remove("border-cyan-800")
        checkIcon.classList.add("text-gray-400")
        checkIcon.classList.remove("text-cyan-800")
      }
    })
  }

  toggleTab(event) {
    const selectedTab = event.currentTarget.dataset.process
    if (this.activeTabs.includes(selectedTab)) {
      this.activeTabs.splice(this.activeTabs.indexOf(selectedTab), 1)
    } else {
      this.activeTabs.push(selectedTab)
    }
    this.displayActiveTabs()
    this.displayProcessItems()
  }

  displayProcessItems() {
    let showEmptyState = true
    this.processItemTargets.forEach(item => {
      if (this.activeTabs.some(tab => item.dataset.processSerie.includes(tab))) {
        item.classList.remove("hidden")
        showEmptyState = false
      } else {
        item.classList.add("hidden")
      }
    })
    if (showEmptyState) {
      this.emptyMessageTarget.classList.remove("hidden")
    } else {
      this.emptyMessageTarget.classList.add("hidden")
    }
  }
}
