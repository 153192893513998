import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preview"
export default class extends Controller {
  static targets = ["input", "preview"]
  connect() {
  }

  preview() {
    // TODO
    let input = this.inputTarget
    let preview = this.previewTarget
    let file = input.files[0]
    let reader = new FileReader()

    reader.onloadend = function() {
      preview.src = reader.result
    }
    if (file) {
      reader.readAsDataURL(file)
      // location.reload();
    } else {
      preview.src = ""
    }
  }

  // deleteImage(event) {
  //   event.preventDefault();
  //   this.inputTarget.value = "";
  //   this.previewTarget.src = "";
  // }
}
