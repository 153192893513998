import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button", "toggle"]
  initialize() {
    console.log('Send button controller initialized')
  }

  toggleCondition() {
    this.toggleTarget.classList.toggle('bg-gray-200')
    this.toggleTarget.classList.toggle('bg-cyan-600')
    const bullet = this.toggleTarget.querySelector('#bullet')
    bullet.classList.toggle('translate-x-0')
    bullet.classList.toggle('translate-x-3.5')
    this.buttonTarget.disabled = !this.buttonTarget.disabled
  }
}