import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  initialize() {
    console.log("Toggle Content initialized!")
    // this.contentTarget.classList.toggle("hidden")
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden")
  }
}